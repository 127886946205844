import { Workbox } from 'workbox-window';
import { registerRoute } from 'workbox-routing';
import { CacheFirst } from 'workbox-strategies';
import { CacheableResponsePlugin } from 'workbox-cacheable-response';
import { ExpirationPlugin } from 'workbox-expiration';
import { RangeRequestsPlugin } from 'workbox-range-requests';
import { toast } from 'react-hot-toast';

const registerSW = () => {
  const workbox = new Workbox('/service-worker.js');

  // would register the service worker on the "load" event
  workbox.register();

  workbox.addEventListener('activated', (event) => {
    // `event.isUpdate` will be true if another version of the service
    // worker was controlling the page when this version was registered.
    if (!event.isUpdate) {
      // eslint-disable-next-line
      console.log('Service worker activated for the first time!');

      // If your service worker is configured to precache assets, those
      // assets should all be available now.
    }
  });

  // eslint-disable-next-line
  const forceUpdate = async (event: any) => {
    // Set up the reload listener first
    workbox.addEventListener('controlling', () => {
      window.location.reload();
    });

    // Skip the waiting phase and activate the new service worker
    workbox.messageSkipWaiting();
  };

  // eslint-disable-next-line
  const showSkipWaitingPrompt = async (event: any) => {
    // Assuming the user accepted the update, set up a listener
    // that will reload the page as soon as the previously waiting
    // service worker has taken control.
    workbox.addEventListener('controlling', () => {
      // At this point, reloading will ensure that the current
      // tab is loaded under the control of the new service worker.
      // Depending on your web app, you may want to auto-save or
      // persist transient state before triggering the reload.
      window.location.reload();
    });

    // When `event.wasWaitingBeforeRegister` is true, a previously
    // updated service worker is still waiting.
    // You may want to customize the UI prompt accordingly.

    const closeToast = ({ toastId }: { toastId: string }) => {
      toast.dismiss(toastId);
    };

    const handleCancel = ({ toastId }: { toastId: string }) => {
      closeToast({ toastId });
    };

    const handleAccept = ({ toastId }: { toastId: string }) => {
      workbox.messageSkipWaiting();
      closeToast({ toastId });
    };

    toast.custom(
      (t) => (
        <div className="flex gap-2 border-y border-r border-gray-100 bg-white shadow-xl">
          <div className="h-full w-1 bg-base-brand"></div>
          <div className="flex flex-col gap-2 px-5 py-3">
            <span className="text-base font-medium">New version of this website is available</span>
            <div className="flex flex-row gap-3 text-sm font-medium">
              <button className="text-base-brand" onClick={() => handleAccept({ toastId: t.id })}>
                Update Now
              </button>
              <button className="text-gray-600" onClick={() => handleCancel({ toastId: t.id })}>
                I&apos;ll do it Later
              </button>
            </div>
          </div>
        </div>
      ),
      {
        duration: Infinity,
      }
    );
  };

  workbox.addEventListener('waiting', (event) => {
    console.log('You need to close all the tabs for this to work ');
    // show a toast
    // eslint-disable-next-line
    // if (true) {
    //     workbox.messageSkipWaiting();
    // }
    forceUpdate(event);
  });

  // In your service worker:
  // It's up to you to either precache, use warmRuntimeCache, or
  // explicitly call cache.add() to populate the cache with media assets.
  // If you choose to cache media assets up front, do so with care,
  // as they can be quite large and exceed storage quotas.
  //
  // This route will go to the network if there isn't a cache match,
  // but it won't populate the cache at runtime because the response for
  // the media asset will be a partial 206 response. If there is a cache
  // match, then it will properly serve partial responses.
  registerRoute(
    ({ request }) => {
      const { destination } = request;

      return destination === 'video' || destination === 'audio';
    },
    new CacheFirst({
      cacheName: 'media-assets-cache',
      plugins: [
        new CacheableResponsePlugin({
          statuses: [200],
        }),
        new RangeRequestsPlugin(),
      ],
    })
  );
  // Cache images from the same origin
  registerRoute(
    ({ request }) =>
      request.destination === 'image' &&
      (request.url as unknown as URL).origin === self.location.origin,
    new CacheFirst({
      cacheName: 'same-origin-image-cache',
      plugins: [
        new CacheableResponsePlugin({
          statuses: [200], // Only cache responses with a 200 status code
        }),
        new ExpirationPlugin({
          maxEntries: 30,
          maxAgeSeconds: 31536000, // one year
        }),
      ],
    })
  );

  // TODO: Cache images from different origins
  // registerRoute(
  //   ({ request }) => {
  //     console.log('Checking if route is getting registered:', request);
  //     return (
  //       request.destination === 'image' &&
  //       (request.url as unknown as URL).origin !== self.location.origin
  //     );
  //   },
  //   new CacheFirst({
  //     cacheName: 'cross-origin-image-cache',
  //     plugins: [
  //       new CacheableResponsePlugin({
  //         statuses: [200], // Only cache responses with a 200 status code
  //       }),
  //       new ExpirationPlugin({
  //         maxEntries: 30,
  //         maxAgeSeconds: 31536000, // one year
  //       }),
  //     ],
  //   })
  // );
};

export { registerSW };
